import React from "react";
import Carousel from "../../../components/editorial/CarouselVideo";
import data from '../../../../queries/data/stream';
import Layout from "../../../UI/Layout";
import FAQ from "../../../components/editorial/FAQ";

const index = () => {

  const carousel = {
    mainCarousel : [
      ...data.data.wp.stream.options
    ]
  }

  const dataHeader = data.data.wp.stream.dataHeader;
  const faq = data.data.wp.stream.faq;

  return (
    <Layout className="Editorial" dataHeader={dataHeader}>
      <Carousel noScroll noTitle info={carousel}/>
      {faq && (
        <div id="streamFaqs">
          {faq.map((faq, i) => <FAQ key={i} closed={!!i} noVersion={true} faq={faq}/>)}
        </div>
      )}
    </Layout>
  )
}

export default index;