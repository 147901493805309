import React, {useState} from 'react';

const FAQ = ({faq, closed, noVersion}) => {
    const [showLegal, setShowLegal] = useState(false)

    return (
        <div className="Legal">
            <div className="tit" onClick={() => setShowLegal(!showLegal)}>
                <h3>{faq?.q}</h3>
                {showLegal ? <h4>-</h4>: <h4>+</h4>}
            </div>
            {showLegal && (
                <>
                    <div className="desc">
                        <div dangerouslySetInnerHTML={{__html: faq?.a}}></div>
                    </div>
                </>
            )}
        </div>
    )
}

export default FAQ